<template>
  <div class="certificationPage">
    <div class="navBar">
      <div @click="$router.go(-1)" class="back">
        <svg-icon class="leftBtn" iconClass="back"></svg-icon>
      </div>
      <div class="title">我的认证</div>
      <div class="rightBtn"></div>
    </div>
    <div class="main">
      <div class="certificaBg"></div>
      <svg-icon class="certificatLogo" iconClass="certificationLogo"></svg-icon>
      <div class="privilege">
        <div class="privilegeItem">
          <svg-icon class="privilegeIcon" iconClass="vLogo"></svg-icon>
          <div class="privilegeTitle">大V标识</div>
        </div>
        <div class="privilegeItem">
          <svg-icon class="privilegeIcon" iconClass="trafficSupport"></svg-icon>
          <div class="privilegeTitle">流量扶持</div>
        </div>
        <div class="privilegeItem">
          <svg-icon class="privilegeIcon" iconClass="playRecom"></svg-icon>
          <div class="privilegeTitle">热播推荐</div>
        </div>
        <div class="privilegeItem">
          <svg-icon class="privilegeIcon" iconClass="downFile"></svg-icon>
          <div class="privilegeTitle">优先审核</div>
        </div>
        <div class="privilegeItem">
          <svg-icon class="privilegeIcon" iconClass="withdraw"></svg-icon>
          <div class="privilegeTitle">快速提现</div>
        </div>
      </div>
      <div class="claimMain">
        <div class="claimTitle">大V特权说明</div>
        <div class="claimBody">
          <div class="claimItem">
            <div class="claimSubTitle">大V标识</div>
            <div class="claimDesc">更加权威提高可信度</div>
          </div>
          <div class="claimItem">
            <div class="claimSubTitle">优质推荐</div>
            <div class="claimDesc">首页/搜索页特殊推荐展示</div>
          </div>
          <div class="claimItem">
            <div class="claimSubTitle">流量扶持</div>
            <div class="claimDesc">更多推荐快速涨粉变现</div>
          </div>
          <div class="claimItem">
            <div class="claimSubTitle">优先审核</div>
            <div class="claimDesc">专属客服优先审核</div>
          </div>
          <div class="claimItem">
            <div class="claimSubTitle">专属客服</div>
            <div class="claimDesc">专人对接优先解决</div>
          </div>
          <div class="claimItem">
            <div class="claimSubTitle">线下活动</div>
            <div class="claimDesc">平台赞助体验线下交友</div>
          </div>
        </div>
      </div>
      <div class="claimTip">认证提示:</div>
      <div class="claimText">1.大V认证需支付5000金币/年的认证服务费</div>
      <div class="claimText">2.认证后需定期发布定期视频，否则将不显示大V图标，直至再次投稿</div>
      <div class="claimText">3.平台不会主动私聊你进行认证，任何主动私聊提醒认证的都是骗子</div>
      <!-- 立即认证 -->
      <div class="submitBox">
        <div class="tip" v-if="uInfo.superUser < 1">
          审核结果会在
          <span>15天内</span>
          以站内消息形式进行通知
        </div>
        <div class="certificatBtn" @click="submitCerificat">{{ btnText }}</div>
      </div>
    </div>
    <!-- 会员购买弹窗-->
    <VipBuy :show="isVipbuyShow" content="需要会员权限才可以认证哦～" vipText="" @close="isVipbuyShow = false" />

    <!-- 支付方式弹窗 -->
    <van-popup class="rechargePop" v-model="isPay" position="bottom">
      <div class="rechargeTitle">
        请选择支付方式
        <svg-icon class="payClose" iconClass="payClose" @click.native="isPay = false"></svg-icon>
      </div>
      <div class="payMethod">支付方式：金币</div>
      <van-radio-group v-model="activeItem" direction="horizontal" class="rechargeList">
        <div class="rechargeItem">
          <svg-icon class="payIcon" iconClass="vipGold"></svg-icon>
          <div class="payText">（金币）特殊行业支付失败 请多试几次</div>
          <van-radio checked-color="rgb(255,103,143)" icon-size="16px" name="gold"></van-radio>
        </div>
      </van-radio-group>
      <div class="payTip">1、跳转后请及时付款，超时支付无法到账，要重新发起</div>
      <div class="payTip">2、每天发起支付不能超过5次，连续发起且未支付，账号可能被加入黑名单</div>
      <div class="customerService">
        支付遇到问题
        <span>联系客服</span>
      </div>
      <div class="payBtn" @click="toPay">立即支付</div>
      <div class=""></div>
    </van-popup>
  </div>
</template>

<script>
import VipBuy from '@/components/VipBuy';
import { queryCialcertResult } from '@/api/mine';
import { Field, Toast, Popup, RadioGroup, Radio } from 'vant';
import { goldExchange } from '@/api/mine';
export default {
  name: 'certificationPage',
  components: {
    VipBuy,
    [Field.name]: Field,
    [Popup.name]: Popup,
    [Radio.name]: Radio,
    [RadioGroup.name]: RadioGroup,
  },
  data() {
    return {
      nameVal: '',
      birthday: '',
      isVipbuyShow: false,
      activeItem: 'gold',
      isPay: false,
      productID: '',
      discountedPrice: '',
      processingStatus: 0,
      btnText: '立即支付',
      uInfo: {},
    };
  },
  created() {
    this.getUserInfo();
    this.queryCialcertResult();
  },
  methods: {
    async getUserInfo() {
      await this.$store.dispatch('user/getUserInfo');
      this.uInfo = this.$store.getters.userInfo;
    },
    // 查询认证状态
    async queryCialcertResult() {
      this.$store.commit('app/SET_LOADING', true);
      try {
        let res = await this.$Api(queryCialcertResult);
        this.$store.commit('app/SET_LOADING', false);
        if (res && res.code == 200) {
          this.processingStatus = res.data.officialcer.processingStatus;
          this.discountedPrice = res.data.productInfo.originalPrice;
          this.productID = res.data.productInfo.productID;

          if (this.uInfo.superUser > 0) {
            this.btnText = '已认证';
          } else if (this.processingStatus == 1 || this.processingStatus == 2) {
            this.btnText = '审核中';
          } else {
            this.btnText = '立即认证';
          }
          // console.log(this.processingStatus, '-')
          // console.log(this.uInfo.superUser)
          // console.log(this.processingStatus)
          return;
        }
        Toast(res.tip || '查询失败');
      } catch (error) {
        this.$store.commit('app/SET_LOADING', false);
        Toast('查询失败');
      }
    },
    // 提交认证
    async submitCerificat() {
      if (this.uInfo.superUser > 0 || this.processingStatus == 1 || this.processingStatus == 2) {
        return;
      }
      this.isPay = true;

      // let uInfo = this.$store.getters.userInfo;
      // if (uInfo.vipLevel == 0 && !uInfo.isVip) {
      //     this.isVipbuyShow = true;
      //     return;
      // }
    },
    async toPay() {
      let req = {
        isH5: true,
        productType: 16, // 认证支付-16
        productID: this.productID,
      };
      try {
        await this.$store.dispatch('user/getWallet');
        let walletInfo = this.$store.getters.walletInfo;
        let walletBalance = (walletInfo.amount || 0 + walletInfo.income || 0).toFixed(2);
        if (this.discountedPrice > walletBalance) {
          Toast('金币不足请充值');
          return;
        }
        this.$store.commit('app/SET_LOADING', true);
        let ret = await this.$Api(goldExchange, req);
        if (ret && ret.code === 200) {
          this.getUserInfo();
          this.queryCialcertResult();
          this.isPay = false;
          Toast('提交成功');
        } else if (ret.tip) {
          Toast(ret.tip || '支付失败，请稍后再试');
        }
        this.$store.commit('app/SET_LOADING', false);
      } catch (error) {
        this.$store.commit('app/SET_LOADING', false);
        Toast('支付失败，请稍后再试');
      }
    },
    close() {},
  },
};
</script>

<style lang="scss" scoped>
.certificationPage {
  height: 100%;
  .navBar {
    height: 44px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 16px;
    background-color: #fff;
    box-sizing: border-box;
    border-bottom: 1px solid #e0e0e0;
    .back {
      width: 80px;
      display: flex;
      align-items: center;

      .leftBtn {
        width: 24px;
        height: 24px;
        font-size: 24px;
      }
    }

    .title {
      flex: 1;
      font-size: 18px;
      text-align: center;
      color: #000;
    }

    .rightBtn {
      width: 80px;
    }
  }
  .main {
    height: calc(100% - 44px);
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    padding: 10px 12px;
    .certificaBg {
      width: 100%;
      height: 140px;
      border-radius: 4px;
      background-image: url('../../../assets/png/rz.jpg');
      background-size: 100% 100%;
    }
    .certificatLogo {
      width: 83px;
      height: 26px;
      margin: 12px 3px 8px;

      .certificatLogo {
        width: 140px;
        height: 34.3px;
        margin: 54px 0 22.7px;
      }
    }
    .privilege {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .privilegeItem {
        display: flex;
        flex-direction: column;
        align-items: center;
        .privilegeIcon {
          width: 47px;
          height: 47px;
        }
        .privilegeTitle {
          width: 60px;
          margin-top: 6px;
          text-align: center;
          font-size: 13px;
        }
      }
    }
    .claimMain {
      margin: 20px auto 0;
      padding: 10px 12px 0px;
      border-radius: 4px;
      background: #fff;
      .claimTitle {
        font-size: 16px;
        text-align: center;
        margin-bottom: 14px;
      }
      .claimBody {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        .claimItem {
          width: 140px;
          margin-bottom: 12px;
          .claimSubTitle {
            font-size: 14px;
            margin-bottom: 5px;
            color: rgb(102, 102, 102);
          }
          .claimDesc {
            font-size: 12px;
            margin-bottom: 5px;
            color: #a3a3a3;
          }
        }
      }
    }
    .claimTip {
      padding: 14px 0px 8px;
      font-size: 16px;
      font-weight: 800;
    }
    .claimText {
      margin-bottom: 4px;
      font-size: 14px;
      color: rgb(102, 102, 102);
    }
    .submitBox {
      width: 100%;
      margin: 14px 0 20px;
      .tip {
        font-size: 12px;
        text-align: center;
        color: rgb(102, 102, 102);
        span {
          color: rgb(255, 103, 143);
        }
      }
      .certificatBtn {
        width: 90%;
        height: 38px;
        line-height: 38px;
        border-radius: 4px;
        font-size: 16px;
        color: #fff;
        margin: 8px auto 0px;
        text-align: center;
        background: rgb(255, 103, 143);
      }
    }
  }

  .rechargePop {
    border-radius: 6px 6px 0 0;
    background-color: rgb(255, 255, 255);
    padding: 0 12px;
    box-sizing: border-box;
    .rechargeTitle {
      height: 60px;
      line-height: 60px;
      font-size: 18px;
      text-align: center;
      border-bottom: 1px solid rgb(239, 238, 245);
      position: relative;
      .payClose {
        height: 22px;
        width: 22px;
        position: absolute;
        top: 50%;
        right: 12px;
        transform: translate(0, -50%);
      }
    }

    .rechargeList {
      max-height: 310px;
      overflow-y: auto;
      -webkit-overflow-scrolling: touch;
      padding: 12px 0px;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      flex-wrap: nowrap;
    }

    .payMethod {
      font-size: 16px;
      margin-top: 12px;
    }

    .rechargeItem {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .payIcon {
        width: 40px;
        height: 40px;
      }

      .payText {
        flex: 2;
        font-size: 14px;
      }
    }

    .payTip {
      font-size: 14px;
      color: rgb(153, 153, 153);
    }

    .customerService {
      font-size: 12px;
      text-align: center;
      margin: 20px 0 6px;
      color: rgb(102, 102, 102);
      span {
        color: rgb(255, 103, 143);
      }
    }

    .payBtn {
      height: 38px;
      line-height: 38px;
      border-radius: 6px;
      font-size: 16px;
      text-align: center;
      color: #fff;
      background: rgb(255, 103, 143);
      margin-bottom: 30px;
    }
  }
}

.payPop {
  background: #00000000;

  .popContent {
    width: 314px;
    height: 302px;
    border-radius: 17px;
    background: linear-gradient(to bottom right, #ffebd9, #ffffff);
  }
}

.mb16 {
  margin-bottom: 16px;
}

.mb100 {
  margin-bottom: 150px;
}
</style>
